@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700&subset=latin-ext";
@import "https://fonts.googleapis.com/css2?family=Roboto&display=swap";

@import "./_functions.scss";

$mtd-parts-green: #059353;
$ada-green: #06653a;

$bg-color: #262626;
$body-color: #262626;
$btn-border-radius: rem(4px);
$link-color: $ada-green;
$nav-link-color: #262626;
$transparent-color: transparent;
$border-color: #d2d2d2;

$theme-colors: (
  "primary": $ada-green,
  "secondary": #262626,
  "success": $ada-green,
  "light": #e8e8e8,
  "dark": #d2d2d2,
);

$colors: (
  "green": $ada-green,
);

$nav-tabs-border-color: #d3d3d3;
$nav-tabs-border-radius: 0;
$nav-tabs-border-width: em(2);
$nav-tabs-link-active-color: #262626;
$nav-tabs-link-active-bg: #e0f4ed;
$nav-tabs-link-active-border-color: $transparent-color $transparent-color
  $ada-green;

@import "../../node_modules/bootstrap/scss/bootstrap";

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .border#{$infix}-top {
      border-top: $border-width solid $border-color !important;
    }
    .border#{$infix}-right {
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-bottom {
      border-bottom: $border-width solid $border-color !important;
    }
    .border#{$infix}-left {
      border-left: $border-width solid $border-color !important;
    }

    .border#{$infix}-top-0 {
      border-top: 0 !important;
    }
    .border#{$infix}-right-0 {
      border-right: 0 !important;
    }
    .border#{$infix}-bottom-0 {
      border-bottom: 0 !important;
    }
    .border#{$infix}-left-0 {
      border-left: 0 !important;
    }

    .border#{$infix}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border#{$infix}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
    // support of .border-sm (-md, -lg, -xl)
    .border#{$infix} {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }
    .border#{$infix}-0 {
      border-top: 0 !important;
      border-bottom: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }
}

.fs-7 {
  font-size: ($font-size-base * 0.85);
}

.fs-8 {
  font-size: ($font-size-base * 0.65);
}

.fs-9 {
  font-size: ($font-size-base * 0.45);
}

.mh-0 {
  max-height: 0;
}

.border-footer {
  border-bottom: 1px solid #4c4c4c;
}

a.text-white {
  &:hover {
    color: #cbcbcb !important;
  }
}

$hamburgerColor: #e1e1e1;
.btn-hamburger {
  @include button-variant(
    $hamburgerColor,
    darken($hamburgerColor, 7.5%),
    darken($hamburgerColor, 10%),
    lighten($hamburgerColor, 5%),
    lighten($hamburgerColor, 10%),
    darken($hamburgerColor, 30%)
  );
  min-width: em(48);
  min-height: em(48);
  padding: em(4);
  border-width: rem(0);

  //   &:active {
  //     background-color: navy;
  //   }

  &:hover {
    background-color: $hamburgerColor;
  }
}

.max-w-20 {
  max-width: em(20);
}

.max-w-25 {
  max-width: em(25);
}

.max-w-48 {
  max-width: em(48);
}

.max-w-120 {
  max-width: em(120);
}

.max-w-160 {
  max-width: em(160);
}

.max-h-25 {
  max-height: em(25);
}

.max-h-48 {
  max-width: em(48);
}

.min-h-48 {
  min-height: em(48);
}

.max-vh-100 {
  max-height: 100vh;
}

.mh-0 {
  max-height: 0;
}

.min-vh-0 {
  min-height: 0 !important;
}

svg {
  width: 100%;
  height: auto;

  &.fill-white {
    * {
      fill: #fff;
    }
  }

  &.fill-black {
    * {
      fill: #000;
    }
  }
}

.box-shadow {
  box-shadow: 0 0 rem(8) rgba(#000, 0.5);
}

body::-webkit-scrollbar {
  display: none;
}

.remove-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .w-md-75 {
    max-width: 75%;
  }
}
